import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { library } from '@fortawesome/fontawesome-svg-core'
import { GatsbyImage } from "gatsby-plugin-image"
import Layout from '../components/layout'
import SEO from '../components/seo'
import Hero from '../components/heroSubpage'
import Card from '../components/card'
import CardIcons from '../components/cardIcons.js'
import FadeInWhenVisible from '../components/fadeInWhenVisible'
import Showdown from 'showdown'
import LogoCarousel from '../components/logoCarousel'
import ColumnAlternating from '../components/columnAlternating'
import Ctas from '../components/Ctas.js'

import { fat } from '@fortawesome/pro-thin-svg-icons'

library.add(fat)

const DataCenter = ({ data }) => {
  const post = data.markdownRemark
  const converter = new Showdown.Converter()
  return (
    <Layout>
      <div id="sub-page">
        <Hero
          class="intro-65"
          image={post.frontmatter.image.childImageSharp.gatsbyImageData}
          foreimage={null}
          alt={post.frontmatter.alttext}
          title={post.frontmatter.title}
          subtitle={post.frontmatter.subtitle}
          ctatext={post.frontmatter.ctatext}
          ctaslug={post.frontmatter.ctaslug}
          type="root"
        />
      </div>

      <main>
        <section className="bg-white mb-n5">
          <FadeInWhenVisible>
            <MDBContainer>
              <MDBRow>
                <MDBCol>
                  <div className="font-w-400 text-xs-extra-large divlink text-opening-para" dangerouslySetInnerHTML={{ __html: post.frontmatter.openingpara, }} />
                  <div className="mt-3 richtext divlink" dangerouslySetInnerHTML={{ __html: post.html }} />
                </MDBCol>
              </MDBRow>
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        <section className="bg-white mt-n5">
          <FadeInWhenVisible>
            <LogoCarousel data={post.frontmatter.partnerlogos} />
          </FadeInWhenVisible>
        </section>

        {post.frontmatter.insight && 
          <>
            {post.frontmatter.insight.map((insight, index) => (
              <section className="bg-white-grey-gradient" key={index}>
                <FadeInWhenVisible>
                  <MDBContainer>
                    <MDBRow>
                      <MDBCol md="7">
                        <h2 className={`font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-3 ${!insight.description && "text-center"}`}>{insight.title}</h2>
                          {insight.description &&
                            <div
                              className="mt-3 richtext richtext-image divlink"
                              dangerouslySetInnerHTML={{ __html: converter.makeHtml(insight.description), }}
                            />                  
                          }
                      </MDBCol>
                      {insight.image &&
                        <MDBCol lg="5" className="text-center image-center">
                            <GatsbyImage
                              image={insight.image.childImageSharp.gatsbyImageData}
                              alt={insight.alttext}
                              className="alt-bg-image rounded w-850"
                            />
                        </MDBCol>
                      }
                    </MDBRow> 
                  </MDBContainer>
                  </FadeInWhenVisible>
              </section>
            ))}
          </>
        }

        {post.frontmatter.requirement &&
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <MDBContainer>
                <div className="solution-text">
                  <h2 className="font-alt font-w-700 letter-spacing-1 mb-3 title-xs-large title-large pb-3">{post.frontmatter.requirement.title}</h2>
                  <p className="pb-5 font-w-400 text-medium">{post.frontmatter.requirement.description}</p>

                  {post.frontmatter.requirement.section &&
                    <>
                      {post.frontmatter.requirement.section.map((sections, index) => (
                        <ColumnAlternating
                          key={index}
                          textCol={'7'}
                          imageCol={'5'}
                          title={sections.title}
                          subtitle={sections.subtitle}
                          subtitlecolour={sections.subtitlecolour != 'none' ? sections.subtitlecolour : 'text-very-dark-blue'}
                          description={converter.makeHtml(sections.description)}
                          image={sections.image.childImageSharp.gatsbyImageData}
                          alttext={sections.alttext}
                          placement={sections.placement}
                          titleClass="font-alt font-w-400 letter-spacing-1 mb-3 title-xs-medium title-medium"
                          link={sections.btnlink}
                          colour={sections.btncolour}
                        />
                      ))}
                    </>
                  }
                </div>
              </MDBContainer>
              </FadeInWhenVisible>
          </section>
        }

        {/* <section className="bg-blue-green-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <CtaTwoCol
                title={post.frontmatter.ctatwocol.title}
                description={post.frontmatter.ctatwocol.description}
                link={post.frontmatter.ctatwocol.link}
                linktext={post.frontmatter.ctatwocol.linktext}
                secondlink={post.frontmatter.ctatwocol.secondlink}
                secondlinktext={post.frontmatter.ctatwocol.secondlinktext}                
		            image={post.frontmatter.ctatwocol.image.childImageSharp.gatsbyImageData}
		            alttext={post.frontmatter.ctatwocol.attexttext}
              />
            </MDBContainer>
          </FadeInWhenVisible>
        </section>           */}

        <Ctas data={post.frontmatter.ctas[0]} />

        <section className="bg-white-grey-gradient">
          <FadeInWhenVisible>
            <MDBContainer>
              <h3 className="font-alt font-w-700 letter-spacing-1 title-xs-medium title-large pb-5">
                {post.frontmatter.whyworkuei.title}
              </h3>
              {post.frontmatter.whyworkuei.whywork && (
                <MDBRow>
                  <>
                    {post.frontmatter.whyworkuei.whywork.map(
                      (workwith, index) => (
                        <CardIcons
                          key={index}
                          collg="4"
                          colmd="6"
                          title={workwith.title}
                          image={workwith.image.childImageSharp.gatsbyImageData}
                          alt={workwith.alttext}
                        />
                      )
                    )}
                  </>
                </MDBRow>
              )}
            </MDBContainer>
          </FadeInWhenVisible>
        </section>

        {post.frontmatter.helpfulresources && (
          <section className="bg-white-grey-gradient">
            <FadeInWhenVisible>
              <MDBContainer>
                <h2 className="font-alt font-w-700 letter-spacing-1 title-xs-large title-large text-left">
                  {post.frontmatter.helpfulresources.title}
                </h2>
                {post.frontmatter.helpfulresources.helpfulres && (
                  <MDBRow className="pt-5">
                    <>
                      {post.frontmatter.helpfulresources.helpfulres.map(
                        (helpfulres, index) => (
                          <Fragment key={index}>
                            <Card collg={ post.frontmatter.helpfulresources.helpfulres .length % 3 === 0 ? '4' : post.frontmatter.helpfulresources.helpfulres .length % 4 === 0 ? '3' : '4' }
                              colmd="6"
                              height="9.5rem"
                              title={helpfulres.title}
                              subtitle={helpfulres.subtitle}
                              description={helpfulres.description}
                              image={helpfulres.image.childImageSharp.gatsbyImageData}
                              alt={helpfulres.alttext}
                              placement={helpfulres.placement}
                              link={helpfulres.link ? helpfulres.link : helpfulres.document.publicURL}
                              titleclass="title-small"
                              descriptionClass="text-card-small"
                            />
                          </Fragment>
                        )
                      )}
                    </>
                  </MDBRow>
                )}
              </MDBContainer>
            </FadeInWhenVisible>
          </section>
        )}

        <Ctas data={post.frontmatter.ctas[1]} />

      </main>
    </Layout>
  )
}
export default DataCenter

export function Head({ data, location }) {
  const post = data.markdownRemark
  return (
    <SEO
      title={post.frontmatter.meta.title}
      description={post.frontmatter.meta.description}
      url={location.pathname}
      image={ post.frontmatter.image.childImageSharp?.gatsbyImageData?.images ?.fallback?.src }
      canonical={location.pathname}
    />
  )
}

export const query = graphql`
  query {
    markdownRemark(frontmatter: { name: { eq: "data-center" } }) {
      frontmatter {
        meta {
          title
          description
        }
        title
        subtitle
        ctatext
        ctaslug
        image {
          childImageSharp {
            gatsbyImageData(quality: 90)
          }
        }
        alttext
        openingpara
        requirement {
          title
          description
          section {
            title
            subtitle
            description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            placement
            alttext
            btnlink
            btncolour
          }
        }
        partnerlogos {
          title
          description
          section {
            image {
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 286, height: 100, quality: 100)
 
              }
            }           
            alttext
            link
          }
        }
        podcast {
          title
          subtitle
          description
          placement
          linktext
          link
          alttext
          image {
            childImageSharp {
              gatsbyImageData(quality: 90)
            }
          }
        }
        whyworkuei {
          title
          whywork {
            title
            alttext
            image {
              childImageSharp {
                gatsbyImageData(width: 70, height: 70, quality: 90)
              }
            }
          }
        }
        insight {
          title
          description
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            alttext
         }
        simplify {
          title
          description
          linktext
          link
        }
        helpfulresources {
          title
          helpfulres {
            title
            subtitle
            description
            placement
            link
            alttext
            image {
              childImageSharp {
                gatsbyImageData(quality: 90)
              }
            }
            document {
              publicURL
            }
          }
        }
        ctas {
          title
          description
          image {
            childImageSharp {
              gatsbyImageData(width: 800, quality: 90)
            }
          } 
          alttext
          button {
            link
            linktext
          }
        }
      }
      html
    }
  }
`
