import React from 'react'
import { MDBContainer, MDBRow, MDBCol } from 'mdbreact'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faChevronRight } from '@fortawesome/pro-regular-svg-icons'
import FadeInWhenVisible from './fadeInWhenVisible'
import CustomLink from './CustomLink'
import { GatsbyImage } from 'gatsby-plugin-image'

class ctas extends React.Component {
  render() {
    const Heading = this.props.headingLevel && Number.isInteger(this.props.headingLevel) ? `h${this.props.headingLevel}` : 'h2'

    return (
      <section className="bg-blue-green-gradient">
        <FadeInWhenVisible>
          <MDBContainer>
            <MDBRow>

              <MDBCol lg={!!this.props.data.image ? '7' : '12'} md="12">
                <Heading className="font-alt font-w-700 text-white title-xs-medium title-large">
                  {this.props.data.title}
                </Heading>
                <p className="mt-4 font-w-400 text-white text-sm-large text-large-cta">
                  {this.props.data.description}
                </p>

                <div className="btn-toolbar">
                  {!!this.props.data.button && 
                  <>
                    {this.props.data.button.map((button, index) => (
                      <CustomLink
                        key={index}
                        link={button.link}
                        className="nav-link btn btn-cta-color btn-sm-block mt-4 noeffect"
                      >
                        <FontAwesomeIcon icon={faChevronRight} className="mr-2" />
                          {button.linktext}
                      </CustomLink>     
                    ))}
                  </>
                  }
                </div>
              </MDBCol>

              {!!this.props.data.image && (
                <MDBCol lg="5" md="12" className="text-center image-center mt-md-4 d-none d-lg-block">
                  <GatsbyImage
                    image={this.props.data.image.childImageSharp.gatsbyImageData}
                    alt={this.props.data.alttext}
                    className="alt-bg-image rounded"
                  />
                </MDBCol>
              )}
            </MDBRow>
          </MDBContainer>
        </FadeInWhenVisible>
      </section>
    )
  }
}
export default ctas
